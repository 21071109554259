<template>
  <div>
    <div v-if="configureClicked">
      <div style="margin: 10px">
        <el-button @click="goBack" v-if="alreadyConfigured == false">
          <i class="el-icon-back"></i>
          Back
        </el-button>
      </div>
      <el-tabs v-model="activeTab" style="margin: 10px 0px 0px 10px" type="card">
        <el-tab-pane name="Buttons" label="Buttons">
          <el-button @click="resetButtons" size="mini"> Reset </el-button>
          <el-table :data="currentEntity.entity_view_configuration.buttons" border v-loading="tableLoading"
            v-if="!refresh">
            <el-table-column prop="buttonName" label="Button Name" width="220">
            </el-table-column>
            <el-table-column label="Button Label" width="250">
              <template slot-scope="scope">
                <el-input v-model="scope.row.value"> </el-input>
              </template>
            </el-table-column>
            <el-table-column label="View Status" width="250">
              <template slot-scope="scope">
                <el-switch active-text="Show" inactive-text="Hide" v-model="scope.row.visibility" :disabled="scope.row.buttonName !== 'Duplicate' &&
                  scope.row.buttonName !== 'Save & New'
                  " @change="updateDOM">
                </el-switch>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane name="activeLayout" label="Active Layout">
          <el-radio-group v-model="currentEntity.entity_view_configuration.active_layout" style="display: block"
            @change="updateDOM" v-if="!refresh">
            <el-radio label="STANDARD">Standard</el-radio>
            <el-radio label="PROFILE">Profile</el-radio>
            <el-radio label="COVER">Cover</el-radio>
          </el-radio-group>
        </el-tab-pane>
        <el-tab-pane name="tabs" label="Tabs Configuration">
          <el-row>
            <el-col :span="15" style="margin-right: 10px">
              <el-row style="align-items: center">
                <el-col>
                  <h3 class="h3 f-weight-400 mt-0 d-flex align-center">
                    <span class="text pl-1">Tabs Configuration</span>
                  </h3>
                </el-col>
                <el-col>
                  <el-button style="float: right" size="mini" @click="previewConfiguration">
                    <i class="el-icon-view"></i>
                    Preview
                  </el-button>
                  <el-button @click="resetTabs" size="mini" style="float: right; margin-right: 5px">
                    Reset
                  </el-button>
                </el-col>
              </el-row>
              <el-card>
                <div class="user-menu-container" mt-15>
                  <div :class="currentEntity.entity_view_configuration.tab_groups.length
                      ? 'sidemenu'
                      : 'scrollable px-4 '
                    ">
                    <el-tree v-if="
                      currentEntity.entity_view_configuration.tab_groups
                        .length && !refresh
                    " :data="currentEntity.entity_view_configuration.tab_groups" :props="props"
                      :default-expand-all="true" :indent="40">
                      <template class="custom-tree-node" slot-scope="{ node, data }">
                        <el-card shadow="never" class="each-item pt-0">
                          <h4 class="h4 f-weight-500 mt-0 d-flex align-center">
                            <span class="text pl-1">{{
                              data.label.length > 20
                                ? data.label.slice(0, 20) + "..."
                                : data.label
                            }}
                            </span>
                          </h4>
                          <div class="actions-btn float-right">
                            <el-button type="text" size="medium" @click="moveToTop(data, node)" :disabled="topTabDisabled(node) || disableFeature(node)
                              ">
                              <i class="el-icon-top yale-blue"></i>
                            </el-button>
                            <el-button type="text" size="medium" @click="moveToBottom(data, node)" :disabled="bottomTabDisabled(node) || disableFeature(node)
                              ">
                              <i class="el-icon-bottom yale-blue"></i>
                            </el-button>
                            <el-button type="text" size="medium" @click="editTab(data, node)"
                              :disabled="disableFeature(node)">
                              <i class="el-icon-edit warning"></i>
                            </el-button>
                            <el-button type="text" size="medium" @click="deleteTab(data, node)"
                              :disabled="disableFeature(node)">
                              <i class="el-icon-delete tomato"></i>
                            </el-button>
                          </div>
                        </el-card>
                      </template>
                    </el-tree>
                  </div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="never" class="px-20 mt-2">
                <el-form v-model="form">
                  <el-form-item class="mb-10" label="Type">
                    <br />
                    <el-radio-group v-model="form.type">
                      <el-radio label="Tab" :disabled="editIndex !== -1"></el-radio>
                      <el-radio label="Feature" :disabled="editIndex !== -1"></el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item v-if="form.type == 'Feature'" label="Assigned To">
                    <br />
                    <el-select v-model="form.assignedTo">
                      <el-option v-for="(tab, index) in currentEntity
                        .entity_view_configuration.tab_groups" :key="index" :label="tab.label" :value="tab.label">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item v-if="form.type == 'Feature'" label="Feature Type">
                    <br />
                    <el-select v-model="form.feature_type" @change="getFeatures(false)">
                      <el-option v-for="(feature, index) in feature_types" :key="index" :label="feature.key"
                        :value="feature.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item v-if="form.type == 'Feature'" label="Select Feature">
                    <br />
                    <el-select v-model="form.feature_id" @change="autoFillLabel">
                      <el-option v-for="(feature, index) in features" :key="index" :label="feature.label"
                        :value="feature.id" :disabled="form.feature_type == 'TEMPLATE'
                            ? getFeatureDisabled(feature.id)
                            : false
                          ">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item v-if="
                    form.type == 'Feature' &&
                    form.feature_type == 'RELATIONSHIP' &&
                    form.feature_id !== ''
                  " label="Permissions">
                    <br />
                    <el-checkbox v-model="form.permissions.add">Add New</el-checkbox>
                    <el-checkbox v-model="form.permissions.assign">Assign & Delete</el-checkbox>
                    <el-checkbox v-model="form.permissions.total">Totals</el-checkbox>
                    <el-checkbox v-model="form.permissions.view">View</el-checkbox>
                    <el-checkbox v-model="form.permissions.redirect">Redirect</el-checkbox>
                  </el-form-item>
                  <el-form-item label="Add New Label" v-if="form.type == 'Feature' && form.feature_type == 'RELATIONSHIP' && form.feature_id !== '' && form.permissions.add">
                    <el-input v-model="form.addNewLabel"></el-input>
                  </el-form-item>
                  <el-form-item label="Assign Label" v-if="form.type == 'Feature' && form.feature_type == 'RELATIONSHIP' && form.feature_id !== '' && form.permissions.assign">
                    <el-input v-model="form.assignLabel"></el-input>
                  </el-form-item>
                  <el-form-item
                    v-if="form.type == 'Feature' && form.feature_type == 'RELATIONSHIP' && form.feature_id !== '' && form.permissions.assign" label="Assign view">
                    <el-radio-group v-model="form.permissions.assign_type">
                      <el-radio label="default_view">
                        Default View
                      </el-radio>
                      <el-radio label="detailed_view">
                        Detailed View
                      </el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item v-if="
                    form.type == 'Feature' &&
                    form.feature_type == 'RELATIONSHIP' &&
                    form.feature_id !== ''
                  " label="Filters">
                    <br />
                    <el-select v-model="form.filter" clearable filterable @change="updateDOM">
                      <el-option v-for="(filter, index) in entityFilters" :key="index" :value="filter._id"
                        :label="filter.filter_name">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item class="mb-10" label="Title">
                    <br />
                    <el-input placeholder="Please Enter Label" v-model="form.label"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <br />
                    <el-button type="primary" @click="addTab"> Add </el-button>
                    <el-button @click="clearForm">Cancel</el-button>
                  </el-form-item>
                </el-form>
              </el-card>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane name="actions" label="Quick Actions">
          <el-button type="primary" size="mini" style="float: right" @click="addNewRow">Add New +</el-button>
          <el-table :data="currentEntity.entity_view_configuration.actions" v-if="!refresh">
            <el-table-column label="Label" prop="label"> </el-table-column>
            <el-table-column label="Relationship">
              <template slot-scope="scope">
                {{ getRelationshipLabel(scope.row.relationship) }}
              </template>
            </el-table-column>
            <el-table-column label="Type">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.type == 'add'" type="success">
                  ADD <i class="el-icon-plus" />
                </el-tag>
                <el-tag v-else-if="scope.row.type == 'assign'" type="warning">
                  ASSIGN <i class="el-icon-check" />
                </el-tag>
                <el-tag v-else-if="scope.row.type == 'add_assign'" type="info">
                  ADD & ASSIGN <i class="el-icon-top-right" />
                </el-tag>
                <el-tag v-else-if="scope.row.type == 'view'">
                  VIEW <i class="el-icon-view" />
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column label="Actions">
              <template slot-scope="scope">
                <el-button size="mini" circle plain type="warning" @click="editAction(scope.$index)"><i
                    class="el-icon-edit" /></el-button>
                <el-button size="mini" circle plain type="danger" @click="deleteAction(scope.$index)"><i
                    class="el-icon-delete" /></el-button>
              </template>
            </el-table-column>
            <el-table-column label="Inactive/Active">
              <template slot-scope="scope">
                <el-switch active-color="#13ce66" inactive-color="#ff4949" v-model="scope.row.active"
                  @change="updateDOM"></el-switch>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="centered-div" v-loading="fetchingDataLoading" v-if="configureClicked == false">
      <img src="../../../public/assets/img/icons/Customization Icon.svg" />
      <h1>Entity Customization</h1>
      <span>You have the ability to customize button labels, form template
        availability, <br />relationship tabs, nested relationship tabs, emails,
        files and eSignatures.</span><br />
      <el-button @click="configureEntityView">
        <i class="el-icon-edit"></i>
        Customize
      </el-button>
    </div>
    <dialog-component :title="'Preview'" :visible="previewTabDialog" @before-close="previewTabDialogBox"
      style="height: 100vh">
      <div class="popUp-content">
        <el-row>
          <el-col :span="2">
            <el-button size="mini" style="margin-bottom: 5px"><i class="el-icon-back"></i></el-button>
          </el-col>
          <el-col :span="18">
            <img src="../../../public/assets/img/icons/Name.svg" />
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="currentEntity &&
              currentEntity.entity_view_configuration &&
              currentEntity.entity_view_configuration != undefined &&
              currentEntity.entity_view_configuration.active_layout !==
              'STANDARD'
              ? 5
              : 0
            ">
            <img src="../../../public/assets/img/icons/Profile View - 1.svg" v-if="
              currentEntity &&
              currentEntity.entity_view_configuration &&
              currentEntity.entity_view_configuration != undefined &&
              currentEntity.entity_view_configuration.active_layout ==
              'PROFILE'
            " />
            <img src="../../../public/assets/img/icons/Cover View - 1.svg" v-if="
              currentEntity &&
              currentEntity.entity_view_configuration &&
              currentEntity.entity_view_configuration != undefined &&
              currentEntity.entity_view_configuration.active_layout == 'COVER'
            " />
          </el-col>
          <el-col :span="currentEntity &&
              currentEntity.entity_view_configuration &&
              currentEntity.entity_view_configuration != undefined &&
              currentEntity.entity_view_configuration.active_layout !==
              'STANDARD'
              ? 19
              : 24
            ">
            <div :class="currentEntity &&
                currentEntity.entity_view_configuration &&
                currentEntity.entity_view_configuration != undefined &&
                currentEntity.entity_view_configuration.active_layout == 'COVER'
                ? 'cover'
                : ''
              ">
              <el-tabs v-model="previewActiveTab" type="card" @tab-click="changeSubTab">
                <el-tab-pane v-for="(tab, index) in previewTabs" :key="index" :name="tab" :label="tab">
                  <el-tabs v-if="previewSubTabs[tab].length" v-model="activeSubTab">
                    <el-tab-pane v-for="(sub, index1) in previewSubTabs[tab]" :key="index1" :name="sub" :label="sub">
                    </el-tab-pane>
                  </el-tabs>
                  <img src="../../../public/assets/img/icons/Standard View - 2.svg" v-if="
                    currentEntity &&
                    currentEntity.entity_view_configuration &&
                    currentEntity.entity_view_configuration != undefined &&
                    currentEntity.entity_view_configuration.active_layout ==
                    'STANDARD'
                  " />
                  <img src="../../../public/assets/img/icons/Cover View - 2.svg" v-if="
                    currentEntity &&
                    currentEntity.entity_view_configuration &&
                    currentEntity.entity_view_configuration != undefined &&
                    currentEntity.entity_view_configuration.active_layout ==
                    'COVER'
                  " />
                  <img src="../../../public/assets/img/icons/Profile View - 2.svg" v-if="
                    currentEntity &&
                    currentEntity.entity_view_configuration &&
                    currentEntity.entity_view_configuration != undefined &&
                    currentEntity.entity_view_configuration.active_layout ==
                    'PROFILE'
                  " />
                </el-tab-pane>
              </el-tabs>
            </div>
          </el-col>
        </el-row>
      </div>
    </dialog-component>
    <dialog-component :visible="newActionDialog" :title="'Add New Action'" :containerWidth="'25%'"
      @before-close="newActionDialog = false">
      <label>Label</label><br />
      <el-input placeholder="Enter label" v-model="actionForm.label"></el-input><br /><br />
      <label>Relationship</label><br />
      <el-select style="width: 100%" v-model="actionForm.relationship">
        <el-option v-for="(rel, index) in relationships" :key="index" :value="rel.relationship_id"
          :label="rel.relationship_title">
        </el-option> </el-select><br /><br />
      <label>Type</label><br />
      <el-radio-group v-model="actionForm.type">
        <el-radio label="add">Add</el-radio>
        <el-radio label="assign">Assign</el-radio>
        <el-radio label="add_assign">Assign & Add</el-radio>
        <el-radio label="view">View data</el-radio>
      </el-radio-group>
      <span slot="footer">
        <el-button size="mini" @click="newActionDialog = false">Cancel</el-button>
        <el-button type="primary" size="mini" :disabled="addActionDisabled()" @click="addNewAction">Add</el-button>
      </span>
    </dialog-component>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { makeSlug } from "../../helpers/appHelper";
import * as _ from 'lodash'
export default {
  name: 'entities-EntityViewConfiguration',
  components: [],
  props: [
    'currentEntity'
  ],
  async mounted() {
    this.fetchingDataLoading = true
    if (this.currentEntity && this.currentEntity.entity_view_configuration == undefined) {
      this.configureClicked = false
    }
    else {
      this.checkActionsConfigured()
      await this.fetchRelationshipsAndNestedRelationships()
      this.configureClicked = true
      this.alreadyConfigured = true
    }
    console.log("relationships", this.relationships)
    this.fetchingDataLoading = false
  },
  computed: {
    ...mapGetters("entityRelationships", ["getEntityRelationships", "getEntityNestedRelationships"]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    ...mapGetters("filters", ["getAllEntityFilters"]),
  },
  data() {
    return {
      relationships: [],
      nestedRelationships: [],
      templates: [],
      configureClicked: false,
      activeTab: "Buttons",
      fetchingDataLoading: false,
      tableLoading: false,
      previewTabDialog: false,
      previewTabs: [],
      previewSubTabs: {},
      previewActiveTab: '',
      alreadyConfigured: false,
      otherPermissions: ["Emails", "Files", "eSignatures"],
      feature_types: [
        {
          key: 'Template',
          value: 'TEMPLATE'
        },
        {
          key: 'Relationship',
          value: 'RELATIONSHIP'
        },
        {
          key: 'Nested Relationship',
          value: 'NESTED_RELATIONSHIP'
        },
        {
          key: 'Others',
          value: 'OTHERS'
        }
      ],
      features: [],
      form: {
        label: '',
        type: 'Tab',
        assignedTo: '',
        feature_type: '',
        feature_id: '',
        permissions: {
          add: true,
          assign: true,
          total: true,
          view : true,
          redirect : true,
          assign_type: 'default_view'
        },
        filter: '',
        addNewLabel : 'Add New',
        assignLabel : 'Assign'
      },
      editIndex: -1,
      props: {
        label: 'label',
        children: 'tabs'
      },
      disabledFeaturesList: [],
      refresh: false,
      parentIndex: -1,
      contactDetailsId: '',
      getClass: '',
      entityFilters: [],
      newActionDialog: false,
      actionForm: {
        label: '',
        relationship: '',
        type: 'add',
        active: true,
      },
      actionEditIndex: -1,
    }
  },
  methods: {
    addNewRow() {
      this.newActionDialog = true
    },
    resetActionForm() {
      this.actionForm = {
        label: '',
        relationship: '',
        type: 'add',
        active: true,
      }
      this.actionEditIndex = -1
      this.newActionDialog = false
    },
    addNewAction() {
      this.refresh = true
      if (this.actionEditIndex == -1) {
        this.currentEntity.entity_view_configuration.actions.push(this.actionForm)
      }
      else {
        this.currentEntity.entity_view_configuration.actions[this.actionEditIndex] = { ...this.actionForm }
      }
      this.updateDOM()
      this.resetActionForm()
    },
    addActionDisabled() {
      return this.actionForm.label.trim() == '' || this.actionForm.relationship == '' || this.actionForm.type == ''
    },
    checkActionsConfigured() {
      if (!Object.keys(this.currentEntity.entity_view_configuration).includes('actions')) {
        this.currentEntity.entity_view_configuration['actions'] = []
      }
    },
    getRelationshipLabel(rel_id) {
      return this.relationships.find(e => e.relationship_id == rel_id).relationship_title
    },
    editAction(index) {
      this.actionEditIndex = index
      this.actionForm = { ...this.currentEntity.entity_view_configuration.actions[index] }
      this.newActionDialog = true
    },
    deleteAction(index) {
      this.$confirm("Do you want to delete this action ?", "Are you sure ?",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          type: "warning",
        }).then(() => {
          this.currentEntity.entity_view_configuration.actions.splice(index, 1)
        })
    },
    previewTabDialogBox() {
      this.previewTabDialog = false;
    },
    async configureEntityView() {
      this.fetchingDataLoading = true
      await this.fetchRelationshipsAndNestedRelationships()
      this.currentEntity['entity_view_configuration'] = {
        buttons: [
          {
            buttonName: "Save",
            value: "Save",
            visibility: true
          },
          {
            buttonName: "Save & Next",
            value: "Save & Next",
            visibility: true
          },
          {
            buttonName: "Save & New",
            value: "Save & New",
            visibility: false
          },
          {
            buttonName: "Edit",
            value: "Edit",
            visibility: true
          },
          {
            buttonName: "Update & Next",
            value: "Update & Next",
            visibility: true
          },
          {
            buttonName: "Duplicate",
            value: "Duplicate",
            visibility: false
          }
        ],
        tab_groups: [],
        actions: [],
      }
      switch (this.currentEntity.entity_type) {
        case 'INDIVIDUAL':
          this.currentEntity.entity_view_configuration['active_layout'] = 'PROFILE'
          break
        case 'BUSINESS':
          this.currentEntity.entity_view_configuration['active_layout'] = 'COVER'
          break
        case 'GENERAL':
          this.currentEntity.entity_view_configuration['active_layout'] = 'STANDARD'
          break
      }
      this.fetchingDataLoading = false
      this.configureClicked = true;
    },
    goBack() {
      this.activeTab = "Buttons"
      this.configureClicked = false;
    },
    resetButtons() {
      this.$confirm("Do you want the button labels to be reset to default ?", "Warning", {
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(() => {
        this.tableLoading = true
        this.currentEntity.entity_view_configuration.buttons.map(e => {
          e.value = e.buttonName
          e.visibility = true
        })
        this.tableLoading = false
      })
    },
    previewConfiguration() {
      if (!this.currentEntity.entity_view_configuration.tab_groups.length) {
        this.$message.warning("Please add some tabs to preview")
        return
      }
      this.previewTabs = []
      this.currentEntity.entity_view_configuration.tab_groups.map(e => {
        this.previewTabs.push(e.label)
        if (e.tabs.length > 1) {
          this.previewSubTabs[e.label] = e.tabs.map(f => f.label)
        }
        else {
          this.previewSubTabs[e.label] = []
        }
      })
      this.previewActiveTab = this.previewTabs[0]
      this.activeSubTab = this.previewSubTabs[this.previewActiveTab].length > 1 ? this.previewSubTabs[this.previewActiveTab][0] : ''
      this.previewTabDialog = true
    },
    async fetchRelationshipsAndNestedRelationships() {
      await this.$store.dispatch(
        "entityRelationships/fetchEntityRelationships",
        { entity_id: this.currentEntity._id }
      );
      this.relationships = [...(this.getEntityRelationships || [])];
      await this.$store.dispatch(
        "entityRelationships/fetchEntityNestedRelationships",
        {
          entity_id: this.currentEntity._id,
          fetchAll: true
        }
      );
      this.nestedRelationships = [
        ...(this.getEntityNestedRelationships || []),
      ];
      this.relationships = this.relationships.map((e) => {
        if (e.parent_entity_id == this.$route.params.entity_id) {
          e.owner_type = "PARENT";
          e.relationship_title = e.child_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.child_entity_id;
        } else {
          e.owner_type = "CHILD";
          e.relationship_title = e.parent_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.parent_entity_id;
        }

        return e;
      });
      this.relationships = this.relationships.filter((e) => {
        if (e.owner_type == "CHILD" && !e.is_bidirectional) {
          return false;
        } else {
          return true;
        }
      });
      this.relationships = this.relationships.filter(
        (e) => e.owner_type == "CHILD" || e.representation == "TAB"
      );
      this.nestedRelationships = this.nestedRelationships.map((e) => {
        // e.owner_type = "PARENT";
        e.relationship_title =
          e.parent_relationship_details.relationship_title +
          "/" +
          e.relationship_title;
        // e.relationship_slug =
        //     e.parent_relationship_details.relationship_slug +
        //     "-" +
        //     makeSlug(e.relationship_title);
        // e.relationship_id = e.owner_type == 'PARENT' ? e.child_entity_id : e.parent_entity_id//e.child_entity_id;
        e.parent_entity_data_id = this.entityDataId;
        return e;
      });
      this.nestedRelationships = _.uniqBy(this.nestedRelationships, (rel) => {
        return rel._id
      })
    },
    async addTab() {
      this.refresh = true
      if (this.form.label.trim() == '') {
        this.$message.warning("Please add Title")
        this.refresh = false
        return
      }
      if (this.form.type == 'Tab') {
        if (this.editIndex == -1) {
          if (this.currentEntity.entity_view_configuration.tab_groups.map(e => e.label).includes(this.form.label.trim())) {
            this.$message.warning("You can't add duplicate names for tabs")
            this.refresh = false
            return
          }
          let obj = {
            label: this.form.label.trim(),
            tabs: []
          }
          if (this.currentEntity.entity_view_configuration.tab_groups.length == 0) {
            if (this.currentEntity.entity_type == 'INDIVIDUAL') {
              let contactDetails = this.currentEntity.templates.find(e => e.templateInfo.type == 'STANDARD')
              this.contactDetailsId = contactDetails.templateInfo._id
              let obj1 = {
                label: "Contact Details",
                type: "Feature",
                assignedTo: this.form.label.trim(),
                feature_type: 'TEMPLATE',
                feature_id: this.contactDetailsId,
                permissions: {
                  add: true,
                  assign: true,
                  total: true,
                  view : true,
                  redirect : true,
                  assign_type : 'default_view'
                },
                addNewLabel : 'Add New',
                assignLabel : 'Assign'
              }
              obj.tabs.push(obj1)
            }
            else {
              let obj1 = {
                label: this.currentEntity.templates[0].templateInfo.name,
                type: "Feature",
                assignedTo: this.form.label.trim(),
                feature_type: 'TEMPLATE',
                feature_id: this.currentEntity.templates[0].templateInfo._id,
                permissions: {
                  add: true,
                  assign: true,
                  total: true,
                  view : true,
                  redirect : true,
                  assign_type : 'default_view'
                },
                addNewLabel : 'Add New',
                assignLabel : 'Assign'
              }
              obj.tabs.push(obj1)
            }
          }
          this.currentEntity.entity_view_configuration.tab_groups.push(obj)
        }
        else {
          if (this.currentEntity.entity_view_configuration.tab_groups.filter(e => this.currentEntity.entity_view_configuration.tab_groups.indexOf(e) !== this.editIndex).map(e => e.label).includes(this.form.label.trim())) {
            this.$message.warning("You can't add duplicate names for tabs")
            this.refresh = false
            return
          }
          this.currentEntity.entity_view_configuration.tab_groups[this.editIndex].label = this.form.label.trim()
        }
        this.clearForm()
      }
      else if (this.form.type == 'Feature') {
        if (this.form.assignedTo == '') {
          this.$message.warning("Please assign this feature to specific tab")
          this.refresh = false
          return
        }
        if (this.form.feature_type == '') {
          this.$message.warning("Please select a feature type")
          this.refresh = false
          return
        }
        if (this.form.feature_id == '') {
          this.$message.warning("Please select a feature")
          this.refresh = false
          return
        }
        if (this.editIndex == -1) {
          let index = this.currentEntity.entity_view_configuration.tab_groups.findIndex(e => e.label == this.form.assignedTo)
          if (this.currentEntity.entity_view_configuration.tab_groups[index].tabs.map(e => e.label).includes(this.form.label.trim())) {
            this.$message.warning('You cannot add duplicate names for features')
            this.refresh = false
            return
          }
          this.currentEntity.entity_view_configuration.tab_groups[index].tabs.push({
            label: this.form.label.trim(),
            feature_type: this.form.feature_type,
            feature_id: this.form.feature_id,
            permissions: {
              add: this.form.permissions.add,
              assign: this.form.permissions.assign,
              total: this.form.permissions.total,
              view : this.form.permissions.view,
              redirect : this.form.permissions.redirect,
              assign_type : this.form.permissions.assign_type
            },
            type: "Feature",
            assignedTo: this.form.assignedTo.trim(),
            addNewLabel : this.form.addNewLabel.trim(),
            assignLabel : this.form.assignLabel.trim()
          })
        }
        else {
          if (this.form.assignedTo == this.currentEntity.entity_view_configuration.tab_groups[this.parentIndex].label) {
            if (this.currentEntity.entity_view_configuration.tab_groups[this.parentIndex].tabs.filter(e => this.currentEntity.entity_view_configuration.tab_groups[this.parentIndex].tabs.indexOf(e) !== this.editIndex).map(e => e.label).includes(this.form.label.trim())) {
              this.$message.warning("You cannot add duplicate names for features")
              this.refresh = false
              return
            }
            this.currentEntity.entity_view_configuration.tab_groups[this.parentIndex].tabs[this.editIndex].label = this.form.label.trim()
            this.currentEntity.entity_view_configuration.tab_groups[this.parentIndex].tabs[this.editIndex].feature_type = this.form.feature_type
            this.currentEntity.entity_view_configuration.tab_groups[this.parentIndex].tabs[this.editIndex].feature_id = this.form.feature_id
            this.currentEntity.entity_view_configuration.tab_groups[this.parentIndex].tabs[this.editIndex].permissions = { ...this.form.permissions }
            this.currentEntity.entity_view_configuration.tab_groups[this.parentIndex].tabs[this.editIndex].filter = this.form.filter
            this.currentEntity.entity_view_configuration.tab_groups[this.parentIndex].tabs[this.editIndex].addNewLabel = this.form.addNewLabel
            this.currentEntity.entity_view_configuration.tab_groups[this.parentIndex].tabs[this.editIndex].assignLabel = this.form.assignLabel
          }
          else {
            let index = this.currentEntity.entity_view_configuration.tab_groups.findIndex(e => e.label == this.form.assignedTo)
            if (this.currentEntity.entity_view_configuration.tab_groups[index].tabs.map(e => e.label).includes(this.form.label.trim())) {
              this.$message.warning('You cannot add duplicate names for features')
              this.refresh = false
              return
            }
            this.currentEntity.entity_view_configuration.tab_groups[this.parentIndex].tabs.splice(this.editIndex, 1)
            this.currentEntity.entity_view_configuration.tab_groups[index].tabs.push({
              label: this.form.label.trim(),
              feature_type: this.form.feature_type,
              feature_id: this.form.feature_id,
              permissions: {
                add: this.form.permissions.add,
                assign: this.form.permissions.assign,
                total: this.form.permissions.total
              },
              filter: this.form.filter
            })
          }
        }
        this.clearForm()
      }
      setTimeout(() => {
        this.refresh = false
      })
    },
    clearForm() {
      this.form = {
        label: '',
        type: 'Tab',
        assignedTo: '',
        feature_type: '',
        feature_id: '',
        permissions: {
          add: true,
          assign: true,
          total: true,
          view : true,
          redirect : true,
          assign_type : 'default_view'
        },
        addNewLabel : 'Add New',
        assignLabel : 'Assign'
      },
        this.editIndex = -1
      this.features = []
    },
    editTab(data, node = {}) {
      this.refresh = true
      this.form.label = data.label
      this.form.type = Object.keys(data).includes('tabs') ? 'Tab' : 'Feature'
      this.form.assignedTo = node.parent.data.label ? node.parent.data.label : ''
      this.form.feature_type = data.feature_type ? data.feature_type : ''
      this.form.feature_id = data.feature_id ? data.feature_id : ''
      this.form.permissions.add = data?.permissions?.add ? data?.permissions?.add : false
      this.form.permissions.assign = data?.permissions?.assign ? data?.permissions?.assign : false
      this.form.permissions.total = data?.permissions?.total ? data?.permissions?.total : false
      this.form.permissions.view = data?.permissions?.view ? data?.permissions?.view : false
      this.form.permissions.redirect = data?.permissions?.redirect ? data?.permissions?.redirect : false
      this.form.permissions.assign_type = data?.permissions?.assign_type ? data?.permissions?.assign_type : 'default_view'
      this.form.addNewLabel = data?.addNewLabel ? data?.addNewLabel : 'Add New'
      this.form.assignLabel = data?.assignLabel ? data?.assignLabel : 'Assign'
      this.form.filter = data?.filter
      if (this.form.type == 'Tab') {
        this.editIndex = this.currentEntity.entity_view_configuration.tab_groups.findIndex(e => e.label.toString() == this.form.label.toString())
      }
      else {
        this.parentIndex = this.currentEntity.entity_view_configuration.tab_groups.findIndex(e => e.label.toString() == node.parent.data.label.toString())
        this.editIndex = node.parent.data.tabs.findIndex(e => e.label.toString() == this.form.label.toString())
      }
      if (this.form.type == 'Feature') {
        this.getFeatures(true)
      }
      setTimeout(() => {
        this.refresh = false
      })
    },
    deleteTab(data, node = {}) {
      this.refresh = true
      if (Object.keys(data).includes("tabs")) {
        this.$confirm("If you delete this tab, all the features in this tab will be delete too. Are you sure to delete the tab ?", "Warning", {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          type: "warning"
        }).then(() => {
          let index = this.currentEntity.entity_view_configuration.tab_groups.findIndex(e => e.label.toString() == data.label.toString())
          this.currentEntity.entity_view_configuration.tab_groups.splice(index, 1)
          this.clearForm()
        })
      }
      else {
        this.$confirm("Are you sure delete this feature ?", "Warning", {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          type: "warning"
        }).then(() => {
          let parentIndex = this.currentEntity.entity_view_configuration.tab_groups.findIndex(e => e.label.toString() == node.parent.data.label.toString())
          let childIndex = node.parent.data.tabs.findIndex(e => e.label.toString() == data.label.toString())
          this.currentEntity.entity_view_configuration.tab_groups[parentIndex].tabs.splice(childIndex, 1)
          this.clearForm()
        })
      }
      setTimeout(() => {
        this.refresh = false
      })
    },
    checkTemplateDisabled(temp) {
      if (temp) {
        for (let tab of this.currentEntity.entity_view_configuration.tab_groups) {
          if (this.editIndex !== -1 && this.currentEntity.entity_view_configuration.tab_groups.indexOf(tab) == this.editIndex) {
            continue
          }
          let exists = tab.templates.find(e => e.toString() == temp.templateInfo._id.toString())
          if (exists != undefined) {
            return true
          }
        }
        return false
      }
      return false
    },
    checkRelationDisabled(rel) {
      if (rel) {
        for (let tab of this.currentEntity.entity_view_configuration.tab_groups) {
          if (this.editIndex !== -1 && this.currentEntity.entity_view_configuration.tab_groups.indexOf(tab) == this.editIndex) {
            continue
          }
          let exists = tab.relationships.find(e => e.toString() == rel._id.toString())
          if (exists != undefined) {
            return true
          }
        }
        return false
      }
      return false
    },
    checkNestedRelationshipDisabled(rel) {
      if (rel) {
        for (let tab of this.currentEntity.entity_view_configuration.tab_groups) {
          if (this.editIndex !== -1 && this.currentEntity.entity_view_configuration.tab_groups.indexOf(tab) == this.editIndex) {
            continue
          }
          let exists = tab.nested_relationships.find(e => e.toString() == rel._id.toString())
          if (exists != undefined) {
            return true
          }
        }
        return false
      }
      return false
    },
    checkOtherFeaturesDisabled(feature) {
      if (feature) {
        for (let tab of this.currentEntity.entity_view_configuration.tab_groups) {
          if (this.editIndex !== -1 && this.currentEntity.entity_view_configuration.tab_groups.indexOf(tab) == this.editIndex) {
            continue
          }
          let exists = tab.others.find(e => e.toString() == feature.toString())
          if (exists != undefined) {
            return true
          }
        }
        return false
      }
      return false
    },
    async getFeatures(edit) {
      if (!edit) {
        this.form.feature_id = ''
      }
      this.features = []
      let features;
      let feature;
      switch (this.form.feature_type) {
        case 'TEMPLATE':
          features = this.currentEntity.templates.map(e => e && e.templateInfo && { label: e.templateInfo.name, id: e.templateInfo._id })
          break
        case 'RELATIONSHIP':
          features = this.relationships.map(e => e && { label: e.relationship_title, id: e._id })
          feature = this.relationships.find(e => e._id == this.form.feature_id)
          await this.getEntityFilters(feature, edit)
          break
        case 'NESTED_RELATIONSHIP':
          features = this.nestedRelationships.map(e => e && { label: e.relationship_title, id: e._id })
          break
        case 'OTHERS':
          features = [{ label: 'Files', id: 'Files' }, { label: 'eSignatures', id: 'eSignatures' }, { label: 'Notifications', id: 'Emails' }]
          if (this.currentEntity.entity_type == 'BUSINESS') {
            features.push({ label: 'Contacts', id: 'Contacts' })
          }
      }
      this.features = [...this.features, ...features]
    },
    getFeatureDisabled(feature_id) {
      for (let e of this.currentEntity.entity_view_configuration.tab_groups) {
        if (e && e.tabs && e.tabs.length) {
          let exists = e.tabs.find(f => f && f.feature_id == feature_id)
          let index = e.tabs.indexOf(exists)
          if (this.currentEntity.entity_view_configuration.tab_groups.indexOf(e) == this.parentIndex && index == this.editIndex) {
            exists = undefined
          }
          if (exists !== undefined) {
            return true
          }
        }
      }
      return false
    },
    topTabDisabled(node) {
      if (node && node.data && Object.keys(node.data).includes('tabs')) {
        let index = this.currentEntity.entity_view_configuration.tab_groups.findIndex(e => e == node.data)
        if (index == 0) {
          return true
        }
        return false
      }
      else {
        let index = node.parent.data.tabs.indexOf(node.data)
        if (index == 0) {
          return true
        }
        return false
      }
    },
    bottomTabDisabled(node) {
      if (node && node.data && Object.keys(node.data).includes('tabs')) {
        let index = this.currentEntity.entity_view_configuration.tab_groups.findIndex(e => e == node.data)
        if (index == this.currentEntity.entity_view_configuration.tab_groups.length - 1) {
          return true
        }
        return false
      }
      else {
        let index = node.parent.data.tabs.indexOf(node.data)
        if (index == node.parent.data.tabs.length - 1) {
          return true
        }
        return false
      }
    },
    disableFeature(node) {
      if (node && node.data && !Object.keys(node.data).includes('tabs')) {
        if (node && node.parent.data.tabs.indexOf(node.data) == 0 && this.currentEntity.entity_view_configuration.tab_groups.indexOf(node.parent.data) == 0) {
          return true
        }
        return false
      }
      return false
    },
    moveToTop(data, node = {}) {
      this.refresh = true
      if (Object.keys(data).includes('tabs')) {
        let index = this.currentEntity.entity_view_configuration.tab_groups.findIndex(e => e.label.toString() == data.label.toString())
        if (index > 0) {
          let temp = this.currentEntity.entity_view_configuration.tab_groups[index]
          this.currentEntity.entity_view_configuration.tab_groups[index] = this.currentEntity.entity_view_configuration.tab_groups[index - 1]
          this.currentEntity.entity_view_configuration.tab_groups[index - 1] = temp
          if (index == 1) {
            let obj = this.currentEntity.entity_view_configuration.tab_groups[1].tabs[0]
            obj.assignedTo = this.currentEntity.entity_view_configuration.tab_groups[0].label
            if (this.currentEntity.entity_type == 'INDIVIDUAL') {
              let stdObj = {
                label: "Contact Details",
                type: "Feature",
                assignedTo: this.currentEntity.entity_view_configuration.tab_groups[0].label,
                feature_type: 'TEMPLATE',
                feature_id: this.contactDetailsId
              }
              this.currentEntity.entity_view_configuration.tab_groups[0].tabs.splice(0, 0, stdObj)
              this.currentEntity.entity_view_configuration.tab_groups[1].tabs.splice(0, 1)
            }
            else {
              this.currentEntity.entity_view_configuration.tab_groups[0].tabs.splice(0, 0, obj)
              this.currentEntity.entity_view_configuration.tab_groups[1].tabs.splice(0, 1)
            }
          }
        }
      }
      else {
        let childIndex = node.parent.data.tabs.findIndex(e => e.label.toString() == data.label.toString())
        let parentIndex = this.currentEntity.entity_view_configuration.tab_groups.findIndex(e => e.label.toString() == node.parent.data.label.toString())
        if (childIndex > 0) {
          let temp = this.currentEntity.entity_view_configuration.tab_groups[parentIndex].tabs[childIndex]
          this.currentEntity.entity_view_configuration.tab_groups[parentIndex].tabs[childIndex] = this.currentEntity.entity_view_configuration.tab_groups[parentIndex].tabs[childIndex - 1]
          this.currentEntity.entity_view_configuration.tab_groups[parentIndex].tabs[childIndex - 1] = temp
        }
      }
      setTimeout(() => {
        this.refresh = false
      })
    },
    moveToBottom(data, node = {}) {
      this.refresh = true
      if (Object.keys(data).includes('tabs')) {
        let index = this.currentEntity.entity_view_configuration.tab_groups.findIndex(e => e.label.toString() == data.label.toString())
        if (index < this.currentEntity.entity_view_configuration.tab_groups.length - 1) {
          let temp = this.currentEntity.entity_view_configuration.tab_groups[index]
          this.currentEntity.entity_view_configuration.tab_groups[index] = this.currentEntity.entity_view_configuration.tab_groups[index + 1]
          this.currentEntity.entity_view_configuration.tab_groups[index + 1] = temp
          if (index == 0) {
            let obj = this.currentEntity.entity_view_configuration.tab_groups[1].tabs[0]
            obj.assignedTo = this.currentEntity.entity_view_configuration.tab_groups[0].label
            if (this.currentEntity.entity_type == 'INDIVIDUAL') {
              let stdObj = {
                label: "Contact Details",
                type: "Feature",
                assignedTo: this.currentEntity.entity_view_configuration.tab_groups[0].label,
                feature_type: 'TEMPLATE',
                feature_id: this.contactDetailsId
              }
              this.currentEntity.entity_view_configuration.tab_groups[0].tabs.splice(0, 0, stdObj)
              this.currentEntity.entity_view_configuration.tab_groups[1].tabs.splice(0, 1)
            }
            else {
              this.currentEntity.entity_view_configuration.tab_groups[0].tabs.splice(0, 0, obj)
              this.currentEntity.entity_view_configuration.tab_groups[1].tabs.splice(0, 1)
            }
          }
        }
      }
      else {
        let childIndex = node.parent.data.tabs.findIndex(e => e.label.toString() == data.label.toString())
        let parentIndex = this.currentEntity.entity_view_configuration.tab_groups.findIndex(e => e.label.toString() == node.parent.data.label.toString())
        if (childIndex < node.parent.data.tabs.length - 1) {
          let temp = this.currentEntity.entity_view_configuration.tab_groups[parentIndex].tabs[childIndex]
          this.currentEntity.entity_view_configuration.tab_groups[parentIndex].tabs[childIndex] = this.currentEntity.entity_view_configuration.tab_groups[parentIndex].tabs[childIndex + 1]
          this.currentEntity.entity_view_configuration.tab_groups[parentIndex].tabs[childIndex + 1] = temp
        }
      }
      setTimeout(() => {
        this.refresh = false
      })
    },
    resetTabs() {
      this.$confirm("Do you want the tabs to be reset to default ?", "Warning", {
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel",
        type: "warning"
      }).then(() => {
        this.refresh = true
        this.currentEntity.entity_view_configuration.tab_groups = []
        this.refresh = false
      })
    },
    async autoFillLabel() {
      let feature
      switch (this.form.feature_type) {
        case 'TEMPLATE':
          feature = this.currentEntity.templates.find(e => e.templateInfo._id == this.form.feature_id)
          this.form.label = feature.templateInfo.name
          break
        case 'RELATIONSHIP':
          feature = this.relationships.find(e => e._id == this.form.feature_id)
          this.form.label = feature.relationship_title
          await this.getEntityFilters(feature)
          break
        case 'NESTED_RELATIONSHIP':
          feature = this.nestedRelationships.find(e => e._id == this.form.feature_id)
          this.form.label = feature.relationship_title
          break
        case 'OTHERS':
          this.form.label = this.form.feature_id
          break
      }
    },
    changeSubTab() {
      if (this.previewSubTabs[this.previewActiveTab].length) {
        this.activeSubTab = this.previewSubTabs[this.previewActiveTab][0]
      }
    },
    updateDOM() {
      this.refresh = true
      setTimeout(() => {
        this.refresh = false
      })
    },
    async getEntityFilters(feature, edit = false) {
      if (feature != undefined) {
        let entity_id;
        if (feature.parent_entity_id == this.currentEntity._id) {
          entity_id = feature.child_entity_id;
        } else {
          entity_id = feature.parent_entity_id;
        }
        await this.$store.dispatch("filters/fetchAllEntityFilters", {
          entityId: entity_id,
        });
        this.entityFilters = [];
        if (this.form.filter && !edit) {
          this.form.filter = "";
        }
        if (this.getAllEntityFilters) {
          this.entityFilters = this.getAllEntityFilters;
        }
      }
    },
  }
}
</script>
<style scoped>
.centered-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center horizontally */
  justify-content: center;
  /* Center vertically */
  height: 85vh;
  text-align: center;
  /* Center the text inside */
}

.centered-div img,
.centered-div h1,
.centered-div span,
.centered-div el-button {
  margin: 5px 0;
  /* Add some vertical spacing between items */
}

.buttons {
  display: flex;
  margin-bottom: 10px;
  margin-left: 20px;
}

.button {
  width: 150px;
}

.center-cell-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.user-menu-container {
  background: #f5f7ff;
  border: 1px solid rgba(168, 171, 255, 0.3);
  border-radius: 20px;
  padding: 2em;
  position: relative;

  .menuPreviewButton {
    position: absolute;
    top: -1.5em;
    right: 15em;
    border-radius: 5px;
  }

  .menuSubmitButton {
    position: absolute;
    top: -1.5em;
    right: 2em;
    right: 6.5em;
    border-radius: 5px;
  }

  .menuDeleteButton {
    position: absolute;
    top: -1.5em;
    right: 1em;
    border-radius: 5px;
  }

  .el-tree {
    background-color: transparent;

    .el-tree-node__content {
      height: 6em;

      &:hover {
        background-color: #f7f9fd;
      }
    }
  }

  .each-item {
    width: 25em;
    overflow: visible;
    margin-bottom: 1em;
    min-height: 5em;
    position: relative;
    z-index: 20;

    .el-card__body {
      padding-top: 0.75em;
    }

    &:last-child {
      margin-bottom: 0em;
    }

    &+.sub-item-1 {
      position: relative;
      margin-left: 3.5em;
      z-index: 15;

      &:after {
        content: "";
        position: absolute;
        top: 2em;
        left: -2em;
        width: 2em;
        border-top: 2px dashed #285fd3;
        height: 1px;
      }

      &:before {
        content: "";
        position: absolute;
        top: -3.75em;
        left: -2em;
        width: 2px;
        border-left: 2px dashed #285fd3;
        height: calc(100% + 1em);
      }
    }

    &+.sub-item-2 {
      position: relative;
      margin-left: 5.5em;
      z-index: 15;

      &:after {
        content: "";
        position: absolute;
        top: 2em;
        left: -4em;
        width: 4em;
        border-top: 2px dashed #285fd3;
        height: 1px;
      }

      &:before {
        content: "";
        position: absolute;
        top: -3.75em;
        left: -4em;
        width: 2px;
        border-left: 2px dashed #285fd3;
        height: calc(100% + 1em);
      }
    }
  }
}

.sidemenu {
  height: 420px;
  overflow-y: scroll;
  overflow-x: scroll;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
}

.sidemenu::-webkit-scrollbar {
  width: 0.6em;
}

.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}

.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}

.cover {
  margin-top: 90px;
}
</style>
